import styled from 'styled-components';

import { palette } from '../../theme';

export const StyledButtonLink = styled.button`
    margin: 0;
    padding: 0;
    color: ${palette.common.blue};
    font-size: inherit;
    font-family: thegymgroupsans-Bold;
    line-height: inherit;
    text-decoration: underline;
    background-color: transparent;
    border: 0;
    cursor: pointer;
`;
