import { Fragment, useState } from 'react';

import { ButtonLink } from '../ButtonLink';
import { DirectDebitLogo } from '../DirectDebitLogo';
import { Overlay } from '../Overlay';
import { Paragraph } from '../Paragraph';
import { UnorderedList } from '../UnorderedList';
import {
    StyledHeading,
    StyledStrong,
    StyledHorizontalRule,
} from './DirectDebitInstructionOverlay.styled';
import {
    DirectDebitInstructionOverlayProperties,
    OverlayType,
} from './DirectDebitInstructionOverlay.types';
import { getFormattedDate } from '@tgg/util';

export function DirectDebitInstructionOverlay({
    accountData,
    paymentReference,
    linkText = 'click here',
    defaultIsOpen = false,
    overlayType = OverlayType.COMMERCE,
}: DirectDebitInstructionOverlayProperties) {
    const [isOpen, setIsOpen] = useState(() => defaultIsOpen);
    const {
        accountName,
        bankAccountNumber,
        bankAccountSortCode,
        bankName,
        bankAddress,
        nextBillingDate,
    } = accountData;
    return (
        <>
            <ButtonLink onClick={() => setIsOpen(s => !s)}>
                {linkText}
            </ButtonLink>
            <Overlay
                title="DIRECT DEBIT INSTRUCTIONS"
                open={isOpen}
                handleClose={() => setIsOpen(false)}
            >
                <Paragraph>
                    This is a preview of your direct debit mandate that will be
                    sent to your bank.
                    {overlayType === OverlayType.COMMERCE && (
                        <>
                            <br />
                            <br />
                            <span data-testid="signupLine">
                                You have not yet completed your membership
                                signup process.
                            </span>
                        </>
                    )}
                    <br />
                    <br />
                    <DirectDebitLogo />
                    <br />
                    <br />
                    Please note the Guildford address below is our registered
                    company office NOT the address of The Gym you are joining.
                </Paragraph>

                <StyledHeading>
                    Bank Service User name and address (Company registered
                    address of The Gym Ltd.):
                </StyledHeading>
                <StyledHorizontalRule />

                <Paragraph>
                    <StyledStrong>Address:</StyledStrong>
                    The Gym Ltd.
                    <br />
                    Woodbridge Meadows
                    <br />
                    Guildford
                    <br />
                    Surrey
                    <br />
                    GU1 1BA
                </Paragraph>

                <Paragraph>
                    <StyledStrong>Name(s) of Account Holder(s):</StyledStrong>
                    {accountName}
                </Paragraph>

                <Paragraph>
                    <StyledStrong>
                        Bank / Building Society Account Number:
                    </StyledStrong>
                    {bankAccountNumber}
                </Paragraph>

                <Paragraph>
                    <StyledStrong>Branch Sort Code:</StyledStrong>
                    {bankAccountSortCode}
                </Paragraph>

                <Paragraph>
                    <StyledStrong>
                        Name and Address of your Bank or Building Society:
                    </StyledStrong>
                    {bankName}
                    <br />
                    {bankAddress.map((line, index) => (
                        <Fragment key={line}>
                            {line}
                            {index < bankAddress.length - 1 && <br />}
                        </Fragment>
                    ))}
                </Paragraph>

                <StyledHeading>
                    Instruction to your Bank or Building Society to pay by
                    Direct Debit
                </StyledHeading>
                <StyledHorizontalRule />

                <Paragraph>
                    <StyledStrong>Service User Number:</StyledStrong>
                    243858
                </Paragraph>

                <Paragraph>
                    <StyledStrong>Reference:</StyledStrong>
                    {paymentReference}
                </Paragraph>

                <StyledHeading>
                    Instruction to your Bank or Building Society:
                </StyledHeading>
                <StyledHorizontalRule />

                <Paragraph>
                    Please pay The Gym Ltd. Direct Debits from the account
                    detailed in this Instruction subject to the safeguards
                    assured by the Direct Debit Guarantee. I understand that
                    this Instruction may remain with The Gym Ltd. and, if so,
                    details will be passed electronically to my Bank.
                </Paragraph>

                <Paragraph>
                    <StyledStrong>Date:</StyledStrong>
                    {overlayType === OverlayType.COMMERCE ? (
                        new Intl.DateTimeFormat('en-GB', {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                        }).format(new Date())
                    ) : (
                        <span data-testid="nextBillingDate">
                            {getFormattedDate(nextBillingDate!, 'dd MMMM yyyy')}
                        </span>
                    )}
                </Paragraph>

                <StyledHeading>The Direct Debit Guarantee</StyledHeading>
                <StyledHorizontalRule />
                <UnorderedList>
                    <li>
                        <Paragraph gutterBottom={false}>
                            This Guarantee is offered by all Banks and Building
                            Societies that accept instructions to pay Direct
                            Debits.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph gutterBottom={false}>
                            If there are any changes to the amount, date or
                            frequency of your Direct Debit The Gym Ltd. will
                            notify you
                            <span data-testid="numberOfDaysDue">
                                {' '}
                                {overlayType === OverlayType.COMMERCE
                                    ? '10'
                                    : '3'}{' '}
                            </span>
                            working days in advance of your account being
                            debited or as otherwise agreed. If you request The
                            Gym Ltd. to collect a payment, confirmation of the
                            amount and date will be given to you at the time of
                            the request.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph gutterBottom={false}>
                            If an error is made in the payment of your Direct
                            Debit, by the Gym Ltd. or by your Bank or Building
                            Society, you are entitled to a full and immediate
                            refund of the amount paid from your Bank or Building
                            Society.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph gutterBottom={false}>
                            If you receive a refund you are not entitled to, you
                            must pay it back when The Gym Ltd. asks you to.
                        </Paragraph>
                    </li>
                    <li>
                        <Paragraph gutterBottom={false}>
                            You can cancel a Direct Debit at any time by simply
                            contacting your Bank or Building Society. Written
                            confirmation may be required. Please also notify us.
                        </Paragraph>
                    </li>
                </UnorderedList>
            </Overlay>
        </>
    );
}
