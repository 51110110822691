import styled from 'styled-components';

import { Theme, palette } from '../../theme';
import { Heading } from '../Heading';
import { HorizontalRule } from '../HorizontalRule';

export const StyledDirectDebitInstructionOverlay = styled.div``;

export const StyledHeading = styled(Heading).attrs({ variant: 'h4', as: 'h2' })`
    margin-bottom: 0;
    color: ${palette.common.blue};
    font-weight: normal;
    font-size: 1.5rem;
    font-family: thegymgroupsans-Headline;
    line-height: 2.4rem;
    text-transform: initial;
    &:not(:first-of-type) {
        margin-top: 4rem;
    }
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.7rem;
    }
`;

export const StyledStrong = styled.strong`
    display: block;
    margin-bottom: 0.5rem;
    font-weight: normal;
    font-family: thegymgroupsans-Headline;
`;

export const StyledHorizontalRule = styled(HorizontalRule)`
    margin: 1rem 0;
`;
