import styled from 'styled-components';

import { Heading } from '../../Heading';

export const StyledHeading = styled(Heading)`
    margin-bottom: 2rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        text-align: center;
    }
`;

export const StyledTickBoxesWrapper = styled.div`
    margin: 1rem 0 3rem;

    div {
        & > span:first-child {
            height: 100%;
        }
    }

    & > :first-child {
        margin-bottom: 2rem;
    }

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        max-width: 62.6rem;
        margin: 0 auto 4rem;
    }
`;
